import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <Box
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            position={'fixed'} top={'0'} w={'100%'} zIndex={'200'} display={'flex'} color={'white'} justifyContent={'space-around'} alignItems={'center'} bg={'black'} p='8px' m='0px' fontFamily={'Lora'}>
            <Link to={'/'}>
                <Box fontSize={'30px'} display={'flex'} alignItems={'center'}> <Text>  Habibi Foods</Text> <Text>🍗🍴</Text></Box></Link>
            <Link to={'/gallery'} >  <Text display={["flex","flex","none"]}> Gallery</Text></Link>
        </Box>
    );
}

export default Navbar;