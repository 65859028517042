import { Box, Img, Text } from '@chakra-ui/react';
import React from 'react';
import Intro from './Intro';

function Gallery() {

    return (
        <Box position={'relative'} top={'40px'} fontFamily={'Lora'}>
            <Intro />
            <Box data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000">   <Text textAlign='center' fontWeight={'bold'} fontSize={'20px'}> Entry Gate </Text>
                <Img w={'100%'} maxW={'700px'} m='auto' src={"./images/homepage.webp"} alt="" /></Box>
            <Box data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000">
                <Text textAlign='center' fontWeight={'bold'} fontSize={'20px'}> Inside Sitting place </Text>
                <Img w={'100%'} maxW={'700px'} m='auto' src={"./images/image1.webp"} alt="" />
            </Box>
            <Box data-aos="zoom-in-down"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000">

                <Img w={'100%'} maxW={'700px'} m='auto' src={"./images/image2.webp"} alt="" />
            </Box>
            <footer style={{ backgroundColor: "rgb(20, 83, 154)", color: "white", padding: "5px 6px", fontFamily: "Lora", textAlign: "center" }}> Developed By <a href="http://sukantadeveloper.in">sukantadeveloper.in</a> | © 2023 All rights reserved.</footer>

        </Box>
    );
}

export default Gallery;