import { Box, Img, Text } from '@chakra-ui/react';
import React from 'react';

function Contact() {
    return (
        <Box fontFamily={'Lora'} display={'block'} maxW={'500px'} m='auto' p={'30px'} pb={'29px'} data-aos="zoom-in"

            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
        >
            <Text fontSize={'20px'} fontWeight={'bold'}>
                Contact Info --
            </Text>
            <Box display={'flex'} pt={'5px'}> <Img mr={'5px'} w='25px' src={"./images/callicon.webp"} alt="" />
                <a href="tel:+919999154213"> +919999154213 </a> </Box>

            <Box display={'flex'} pt={'5px'}> <Img mr={'5px'} w='25px' src={"./images/callicon.webp"} alt="" />
                <a href="tel:+917827477333"> +917827477333 </a> </Box>

            <Text pt={'5px'}>
                Address: A-36/37, Abul Fazal Enclave Part 1, Jamia Nagar Okhla, New Delhi-110025
            </Text>
            <Box>
                <a href="https://www.google.com/maps/place/Habibi+Foods,+A69,+Main+Road,+Okhla+Vihar,+Jamia+Nagar,+Okhla,+New+Delhi,+Delhi+110025/data=!4m2!3m1!1s0x390ce5d4af9c4465:0x241618857deee25?utm_source=mstt_1&entry=gps&g_ep=CAESCTExLjQzLjUwMhgAIP___________wEqAEICSU4%3D">
                    <Box display={'flex'} pt={'5px'}>   <Img mr={'5px'} w='30px' src={"./images/map.png"} alt="" />
                        <Text>   Location in Map </Text> </Box></a> </Box>

        </Box>
    );
}

export default Contact;