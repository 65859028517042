import { Box, Text } from '@chakra-ui/react';
import React from 'react';

function Intro() {
    return (
        <Box bg={'rgb(20, 83, 154)'} color={'white'} fontFamily={'Lora'} p='20px 5px'
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000" 
        marginTop={'20px'}
        >
           
            <Text textAlign={'center'} fontSize={'2xl'} > 24/7 Available On Zomato and Swiggy</Text>
        </Box>
    );
}

export default Intro;