import React from 'react';
import Gallery from './Gallery';
import Intro from './Intro';
import { Box, Img, SimpleGrid, Text } from '@chakra-ui/react';
import Contact from './Contact';

function Home() {

    const data = [
        {
            name: "Al-faham Chicken",
            quater: "140",
            half: "280",
            image: "./images/Al-fahamChicken.webp"
        },
        {
            name: "Al faham chicken mandi",
            image: "./images/Al-fahamChickenMandi.webp",
            quater: "170",
            half: "340",
        },
        {
            name: "Hyderabadi special chicken dum biryani",
            image: "./images/specialdumbriyani.png",
            price: "180/KG"
        },
        {
            name: "Chicken tikka",
            image: "./images/chickentikka.png",
            quater: "120",
            half: "240",
        },
        {
            name: "Fish fry",
            image: "./images/fishfry.png",
            quater: "150",
            half: "300",
        },
        {
            name: "Chicken Butter Roasted",
            image: "./images/chickenbutterrosterd.webp",
            quater: "120",
            half: "240",
        },
        {
            name: "Chicken fry",
            image: "./images/chickenfry.webp",
            price: "100",

        },

        {
            name: "Chicken Kabab",
            image: "./images/chickenkabab.webp",
            price: "30",

        },
        {
            name: "Falafel with Hummus",
            image: "./images/falafel.png",
            price: "100",

        },
        {
            name: "Cold Coffee",
            image: "./images/Cold-Coffee.webp",
            price: "70",

        },
        {
            name: "Virjin Mojito",
            image: "./images/virgin-mojito.webp",
            price: "70",

        },

    ]

    return (
        <Box position={'relative'} top={'56px'}>
            <Box bg={'orange'} w={'35%'} p={'10px 2px'} mt={'20px'} style={{ borderTopRightRadius: "40px" }}
                data-aos="fade-right"
            >
                <Text fontFamily={'Lora'} pl={'40px'} fontSize={'19px'} fontWeight={'bold'}> Menu</Text>

            </Box>

            <SimpleGrid columns={[1,2, 3,4]} spacing='10px' width={'99%'} m='auto'>
                {
                    data.map((ele) => (
                        <Box key={ele.name} data-aos="zoom-in-down"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="1000"
                           
                            >
                            {ele.image ?
                                <Box borderRadius={'10px'} fontSize={'15px'} fontWeight={'bold'} fontFamily={'Lora'} maxW={'450px'} h={'390px'} p={'20px 5px'} textAlign={'center'} bg={'white'} boxShadow={'dark-lg'} w={'90%'} m='auto' mt={'25px'}>
                                    <Img src={ele.image} alt="" w={'200px'} maxH={'270px'} m={'auto'} borderRadius={'20%'} />
                                    <Text fontSize={'20px'} fontWeight={'bold'} p={'2px'} color={'orange'}> ⭐⭐⭐⭐⭐</Text>
                                    <Text fontSize={'20px'} fontWeight={'bold'} pb={'10px'}> {ele?.name}</Text>

                                    {!ele.price ? <>  <Box color={'rgb(244, 47, 37)'} display={'flex'} w={'max-content'} m={'auto'} alignItems={'center'}> QUATER PRICE :
                                        <Text pl={'2px'} color={'orange'}> ₹ {ele.quater}</Text></Box>
                                        <Box color={'rgb(244, 47, 37)'} display={'flex'} w={'max-content'} m={'auto'} alignItems={'center'}> HALF PRICE :
                                            <Text pl={'2px'} color={'orange'}> ₹ {ele.half}</Text></Box> </> :
                                        <Box color={'rgb(244, 47, 37)'} display={'flex'} w={'max-content'} m={'auto'} alignItems={'center'}>  PRICE :
                                            <Text pl={'2px'} color={'orange'}> ₹ {ele.price}</Text></Box>}

                                </Box> : ""}</Box>
                    ))
                }
            </SimpleGrid>
            <Intro />
            <Contact />
            <footer style={{ backgroundColor: "rgb(20, 83, 154)", color: "white", padding: "5px 6px", fontFamily: "Lora", textAlign: "center" }}> Developed By <a href="http://sukantadeveloper.in">sukantadeveloper.in</a> | © 2023 All rights reserved.</footer>
        </Box>
    );
}

export default Home;