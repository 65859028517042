import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Gallery from './Gallery';

function AllRoutes() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Home />}>
                </Route>
                <Route path='/gallery' element={<Gallery/>}></Route>

            </Routes>
        </div>
    );
}

export default AllRoutes;